import { BearerT, symptoReq, generateAuthHeader } from '../utils/auth/utils';

const getCampaignsForPatient = async ({
  patientTvId,
  authData,
}: {
  patientTvId: string;
  authData: BearerT;
}): Promise<{
  campaignId: string;
  patientCampaignId: string;
}[]> => {
  const allPatientCampaigns: {
    campaignId: string;
    patientCampaignId: string;
  }[] = await symptoReq(
    `/providers/patients/${patientTvId}/campaigns`,
    {
      method: 'GET',
    },
    generateAuthHeader(authData)
  );
  return allPatientCampaigns.map(({
    campaignId,
    patientCampaignId,
  }) => ({
    campaignId,
    patientCampaignId,
  }));
};

const unenrollPatientFromCampaign = async ({
  campaignId,
  patientTvId,
  currentPatientCampaigns,
  authData,
}: {
  campaignId: string;
  patientTvId: string;
  currentPatientCampaigns: {
    campaignId: string;
    patientCampaignId: string;
  }[];
  authData: BearerT;
}) => {
  try {
    const matchingCampaigns: Array<string> = currentPatientCampaigns.filter(
      ({ campaignId: thisCampaignId }) => thisCampaignId === campaignId
    ).map(({ patientCampaignId }) => patientCampaignId);
    await Promise.all(matchingCampaigns.map(async (patientCampaignId: string) => {
      await symptoReq(
        `/providers/patients/${patientTvId}/campaigns`,
        {
          body: JSON.stringify({ patientCampaignId }),
          method: 'DELETE',
        },
        generateAuthHeader(authData)
      );
    }));
  } catch (e) {
    alert(`Failed to unenroll patient ${patientTvId} from campaign`);
    throw e;
  }
}

const enrollPatientsInRequiredCampaigns = async ({
  authData,
  campaignId,
  currentPatientCampaigns,
  patientTvId,
}: {
  authData: BearerT;
  patientTvId: string;
  currentPatientCampaigns: {
    campaignId: string;
    patientCampaignId: string;
  }[];
  campaignId: string;
}) => {
  try {
    if (currentPatientCampaigns.some(({ campaignId: thisCampaignId }) => thisCampaignId === campaignId)) {
      // Patient is already enrolled in this campaign
      return;
    }
    await symptoReq(
      `/providers/patients/${patientTvId}/campaigns`,
      {
        method: 'POST',
        body: JSON.stringify({ campaignId }),
      },
      generateAuthHeader(authData)
    );
  } catch (e) {
    alert(`Failed to enroll patient ${patientTvId} in campaign`);
    throw e;
  }
};

export const fixCampaignForPatient = async ({
  authData,
  existingPatientCampaignId,
  patientTvId,
  newPatientCampaignId,
}: {
  authData: BearerT;
  existingPatientCampaignId: string;
  patientTvId: string;
  newPatientCampaignId: null | string;
}) => {
  const patientCampaigns = await getCampaignsForPatient({
    patientTvId,
    authData,
  });
  await unenrollPatientFromCampaign({
    campaignId: existingPatientCampaignId,
    patientTvId,
    currentPatientCampaigns: patientCampaigns,
    authData,
  });
  if (newPatientCampaignId != null) {
    await enrollPatientsInRequiredCampaigns({
      authData,
      campaignId: newPatientCampaignId,
      currentPatientCampaigns: patientCampaigns,
      patientTvId,
    });
  }
};
