import _ from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import { bulkUploadParse } from './bulkUploadParse';
import { uploadSymptoData } from './uploadSymptoData';
import { calculateHeaders } from '../utils/csvUtils';
import ErrorLogs from './ErrorLogs';
import FileUpload from '../utils/FileUpload';
import Auth from '../utils/auth/Auth';
import { PatientData } from './bulkUploadParse';

const App = () => {
  const [parsedData, setParsedData] = useState<
    null | {
      headers: string[];
      data: {
        row: PatientData,
        id: string,
        index: number,
      }[],
    }
  >(null);
  const [currentErrors, setErrors] = useState<{
    [key: number]: {
      row: number,
      msg: string,
    }[],
  }>({});
  // include list of unqiue indices uploaded
  const [numUploads, setNumUploads] = useState<number>(0);
  const [fileName, setFileName] = useState<string | null>(null);
  return (
    <div>
      {fileName == null && (
        <FileUpload
          placeholder="Drag 'n Drop Raw CSV"
          allowExcel={false}
          onUpload={(latestUploadData) => {
            try {
              const { data } = bulkUploadParse(latestUploadData);
              const headers = calculateHeaders(data);
              const cleanedData = data.filter((row: PatientData) => {

                return true;
              });
              setParsedData({
                headers,
                data: cleanedData.map((row: PatientData, index: number) => ({
                  row, index, id: uuidv4(),
                })),
              });
              return true;
            } catch (e) {
              swal('Error', (e as Error).message, 'error')
              return false;
            }
          }}
          fileName={null}
          setFileName={(newFileName) => {
            setFileName(newFileName);
          }}
        />
      )}
      {fileName != null && (
        <div className="text-center mt-4 text-secondary">
          <div>
            <span className="text-dark pr-2">File Name: </span>
            {fileName}
          </div>
          <Button variant="link" onClick={() => { window.location.reload(); }}>
            New File
          </Button>
        </div>
      )}
      {parsedData && (
        <Auth
          buttonNode={
            <>
             Login to Mercy Roseburg Clinic and Upload
              {numUploads !== 0
                ? ` (${numUploads}/${parsedData.data.length})`
                : ''}
            </>
          }
          onSubmit={async (authData) => {
            uploadSymptoData(
              parsedData.data.map(({ row }) => row),
              authData,
              (index: number, log: string) => {
                setErrors((curErrors) => ({
                  ...curErrors,
                  [index]: [
                    ...(curErrors[index] || []),
                    {
                      row: index,
                      msg: log,
                    }
                  ],
                }))
              },
              () => {
                setNumUploads((curNumUploads) => (curNumUploads + 1));
              },
            )
          }}
        />
      )}
      {parsedData && (
        <>
          <ErrorLogs
            parsedData={parsedData.data}
            errors={_.flatten(_.values(currentErrors))}
          />
          {currentErrors[-1] != null && (
            <div className="text-danger mb-3" style={{ fontSize: '0.7em' }}>
              {currentErrors[-1].map(({ msg }) => (
                <div className="my-2">{msg}</div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
