import _ from 'lodash';
import { SYMPTO_COLUMNS } from './generalData';

const ALLOWED_MISSING_COLUMNS = ['Emerg Contact Phone'];

export const bulkUploadParse = (data: Record<string, string>[]): {
  data: Record<string, string>[],
} => {
  if (data.some((row) => (
    SYMPTO_COLUMNS.some((col) => (!ALLOWED_MISSING_COLUMNS.includes(col) && row[col] == null))
  ))) {
    const missingColumns = _.uniq(_.compact(_.flatten(data.map((row) => (
      SYMPTO_COLUMNS.map((col) => (row[col] == null ? col : null))
    )))));
    throw new Error(`Invalid upload. Missing ${missingColumns.join(', ')} for some records`);
  }
  return { data };
};
