import React from 'react'
import Dropzone from 'react-dropzone'
import { Card } from 'react-bootstrap';
import swal from 'sweetalert';

const FileUploadJSON = ({
  onUpload,
  fileName,
  placeholder,
  setFileName,
}: {
  setFileName: (fileName: string) => void,
  fileName: string | null,
  placeholder: string,
  onUpload: (data: { [key: string]: string; }[]) => boolean,
}) => (
  <>
    <Dropzone
      onDrop={(acceptedFiles) => {
        const fr = new FileReader();
        fr.onload = async () => {
          const fileName = acceptedFiles[0].name;
          const arrayBuffer = await acceptedFiles[0].text();

          const frResult: {
            [key: string]: string;
          }[] = await (async () => {
            if (!fileName.endsWith('.json')) {
              swal('Error', 'Please upload a JSON file', 'error');
            }
            return JSON.parse(arrayBuffer);
          })();
          // remove empty rows
          const frResultFiltered = frResult.filter((row) => Object.values(row).some((value) => value !== ''));

          if (onUpload(frResultFiltered)) {
            setFileName(fileName);
          }
        };
        fr.readAsText(acceptedFiles[0]);

      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Card {...getRootProps()} className="w-50 mx-auto mt-4 p-5 text-center">
          <input {...getInputProps()} />
          <div className="text-secondary font-weight-light">
            {placeholder}
          </div>
        </Card>
      )}
    </Dropzone>
    {fileName && (
      <div className="text-center mt-4 text-secondary">
        <span className="text-dark pr-2">File Name: </span>
        {fileName}
      </div>
    )}
  </>
);

export default FileUploadJSON;
