export type MMDDYYYYFormat = string;

export type SiteTypesT = 'I17' | 'I18' | 'I16' | 'I19' | 'I23' | 'I15' | 'I14' | 'I22';
export type PriorityLevelT = '2) Medium' | '1) Low' | '3) High' | '';

export type SymptoFields = {
  Sites: SiteTypesT,
  'Full Site Name': string,
  'ED Visit/Referral Date': MMDDYYYYFormat,
  'Provider / On-Site Coordinator Name': string,
  'First Name': string,
  'Last Name': string,
  'PCP Medical Group': string,
  'Encounter Number': string,
  'Date of Birth': MMDDYYYYFormat,
  'Phone Number': string,
  'Alt Phone #': string,
  'Email Address': string,
  'Patient\'s Primary Language': string,
  'Spanish Speaking': 'FALSE' | 'TRUE',
  'Reason for Navigation': string,
  'Priority Level': PriorityLevelT,
  'Encounter Details/Comments': string,
  'Payer Type': string,
  'Payor Category': string,
  'Visits within 6 mo\'s': string,
  'Navigator Name': string,
  'Secondary Navigator Name': string,
  Ethnicity: string,
  Identification: 'Provider Referral' | 'Administrator' | 'Service Intro No Response',
  'No Service Introduction': 'TRUE' | 'FALSE',
  MRN: string,
  Sex: string,
  URL: string,
  Gender: string,
  'Is Pregnant?': 'Yes' | 'No' | 'Unknown',
  Race: string,
  Address: string,
  'Chief Complaint': string,
  'Discharge Diagnosis': string,
  'PCP Provider Name': string,
  'PCP Phone': string,
  'Provider NPI': string,
  'Provider Specialty': string
  'Clinic Name': string,
  'Clinic Address': string,
  'InsuranceID': string,
  'Emerg Contact': string,
  'Emerg Contact Phone': string,
  'Intro Message': string,
  'ED Visit Summary': string,
  Prescriptions: string,
  Referrals: string,
  'Rx Prescribed': string,
  'Secondary Payer Type': string,
  'Secondary InsuranceID': string,
};

export const SYMPTO_COLUMNS = [
  'Sites',
  'Full Site Name',
  'ED Visit/Referral Date',
  'Provider / On-Site Coordinator Name',
  'First Name',
  'Last Name',
  'Encounter Number',
  'Date of Birth',
  'Phone Number',
  'Alt Phone #',
  'Email Address',
  'Patient\'s Primary Language',
  'Spanish Speaking',
  'Reason for Navigation',
  'Priority Level',
  'Encounter Details/Comments',
  'Payer Type',
  'Payor Category',
  'Visits within 6 mo\'s',
  'Navigator Name',
  'Secondary Navigator Name',
  'Identification',
  'No Service Introduction',
  'MRN',
  'Sex',
  'Gender',
  'Is Pregnant?',
  'Race',
  'Ethnicity',
  'Address',
  'Chief Complaint',
  'Discharge Diagnosis',
  'PCP Provider Name',
  'PCP Phone',
  'PCP Medical Group',
  'Provider NPI',
  'Provider Specialty',
  'Clinic Name',
  'Clinic Address',
  'InsuranceID',
  'Emerg Contact',
  'Emerg Contact Phone',
  'Intro Message',
  'ED Visit Summary',
  'Prescriptions',
  'Referrals',
  'Rx Prescribed',
  'Secondary Payer Type',
  'Secondary InsuranceID',
];
