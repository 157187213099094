import React  from 'react';
import { Card } from 'react-bootstrap';
import { ErrorBoundary } from "react-error-boundary";
import { BearerT } from '../utils/auth/utils';
import CampaignDropdown, { CampaignWithPatients } from './CampaignDropdown';

const CampaignSelect = ({
  replacementCampaign,
  setReplacementCampaign,
  activeCampaigns,
  authData,
}: {
  replacementCampaign: null | CampaignWithPatients,
  activeCampaigns: CampaignWithPatients[],
  setReplacementCampaign: (campaign: CampaignWithPatients) => void,
  authData: BearerT,
}) => {
  return (
    <ErrorBoundary fallback={<div>Unable to render active campaigns</div>}>
      <Card className="bg-light w-50 mx-auto p-4 mt-3">
        <CampaignDropdown
          campaignData={activeCampaigns}
          label="Select Replacement Campaign (Optional)"
          selectedCampaign={replacementCampaign}
          setSelectedCampaign={(updatedCampaign) => {
            setReplacementCampaign(updatedCampaign);
          }}
          authData={authData}
        />
      </Card>
    </ErrorBoundary>
  );
};

export default CampaignSelect;
