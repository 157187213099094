import _ from 'lodash';
import React, { useMemo, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { PatientData } from './bulkUploadParse';
import PatientCard from './PatientCard';

const ErrorLogs = ({ parsedData, errors }: {
  errors: {
    // row value maps to index value in parsed data
    row: number,
    msg: string,
  }[],
  parsedData: {
    row: PatientData;
    index: number,
    id: string,
  }[],
}) => {
  const allRows = useMemo(() => {
    const errorRows = errors.map(({ row }) => row);
    const parsedDataRows = parsedData.map(({ index }) => index);
    return _.sortBy(_.uniq([...errorRows, ...parsedDataRows]));
  }, [errors, parsedData]);

  const getInfoForRow = useCallback((row: number) => {
    const error = errors.filter(({ row: errorRow }) => errorRow === row);
    const patientData = parsedData.filter(({ index }) => index === row);
    return { error, patientData, row };
  }, [errors, parsedData]);

  const patientDataPerRow = useMemo(() => (
    allRows.map((row) => getInfoForRow(row))
  ), [allRows, getInfoForRow]);

  const hasErrors = useMemo(() => (
    errors.filter(({ msg }) => msg.startsWith('FAILURE')).length
  ), [errors]);

  const [showErrors, setShowErrors] = useState(false);
  return (
    <div className="ErrorLogs w-50 mx-auto">
      {hasErrors && (
        <Button
        className="text-white font-weight-bold text-small mb-3"
        variant="success"
        onClick={() => setShowErrors(!showErrors)}
      >
          {showErrors ? 'Show All' : 'Show Only Errors'}
        </Button>
      )}
      {!hasErrors && (
        <div className="text-center">
          <h3>No errors found</h3>
        </div>
      )}
      {patientDataPerRow
        .filter(({ error }) => error != null && error.length > 0)
        .filter(({ error }) => showErrors === false || error.filter(({ msg }) => msg.startsWith('FAILURE')).length > 0)
        .map((patientData) => (
         <PatientCard patientData={patientData} />
      ))}
      {hasErrors && (
        <Button
          onClick={() => {
            const str = JSON.stringify(patientDataPerRow.map(({ error }) => error != null));
            const bytes = new TextEncoder().encode(str);
            const blob = new Blob([bytes], {
              type: "application/json;charset=utf-8"
          });

          fileDownload(
            blob,
            `sympto-parsed-data-${new Date().toISOString()}.json`,
          );
        }}
        variant="primary"
        className="text-white"
      >
          Download Error Rows
        </Button>
      )}
    </div>
  );
}

export default ErrorLogs;
