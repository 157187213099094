import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { PatientData } from './bulkUploadParse';
import { getLogType } from '../bulkUploads/logs';

const PatientCard = ({ patientData: { error, patientData, row } }: {
  patientData: {
    error: {
        row: number;
        msg: string;
    }[];
    patientData: {
        row: PatientData;
        index: number;
        id: string;
    }[];
    row: number;
  };
}) => {

  const [expanded, setExpanded] = useState(false);
  const logClassName = useCallback((message: string) => {
    const logType = getLogType(message);
    return cx({
      'text-secondary': logType === 'Intermediate',
      'text-success': logType === 'Success',
      'text-danger': logType === 'Failure',
      'text-primary': logType === 'Primary',
      'text-dark': logType === 'Skipped',
      'text-warning': logType === 'Update',
    });
  }, []);

  return (
    <Card className="bg-light p-3 mb-4 shadow-none" key={row}>
      {error.map(({ msg }) => (
        <div className={cx('font-weight-bold mb-2', logClassName(msg))}>{msg}</div>
      ))}
      {patientData.map(({ row }) => (
        <>
          <div className="border mt-2 bg-white p-3 rounded d-flex align-items-center justify-content-between">
            <div className="font-weight-bold mb-2">
              <div className="font-weight-bold text-small text-secondary">MRN</div>
              {row['MRN']}
            </div>
            <div className="font-weight-bold mb-2">
              <div className="font-weight-bold text-small text-secondary">MRN</div>
              {row['First Name']}
            </div>
            <div className="font-weight-bold mb-2">
              <div className="font-weight-bold text-small text-secondary">Last Name</div>
              {row['Last Name']}
            </div>
            <div className="font-weight-bold mb-2">
              <Button
                variant="primary"
                size="sm"
                onClick={() => window.open(row['url'], '_blank')}
              >
                URL
              </Button>
            </div>
          </div>
          {expanded && (
            <div className="mt-2 text-small">
              {JSON.stringify(row)}
            </div>
          )}
          <Button variant="link" className="font-weight-bold text-primary text-decoration-none mt-3" size="sm" onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Hide Details' : 'Show Details'}
          </Button>
        </>
      ))}
    </Card>
  );
}

export default PatientCard;
