import { getEncounterNumber } from './encounterDataGenerate';
import { EncounterDataT } from '../bulkUploads/symptoUtils';
import { PatientData } from './bulkUploadParse';
import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from '../serviceIntros/utils';

export const isAlreadyExistingEncounter = async ({
  encounterData,
  patientData,
  authCode,
}: {
  encounterData: EncounterDataT[];
  patientData: PatientData;
  authCode: BearerT;
}): Promise<{
  matchingPatientSurveyId: string;
} | null> => {
  const encounterNumber = getEncounterNumber(patientData);

  // for aech survey
  const encounters = encounterData.filter(({ type }) => type === 'encounter');
  // now for each, call analytics

  const existingEncounterNumbers = await Promise.all(encounters.map(async (encounter) => {
    const data = await symptoReq(`/providers/responses/${encounter.patientSurveyId}/analytics`, {
      "body": null,
      "method": "GET",
    }, generateAuthHeader(authCode));
    return {
      encounterFNumber: data['Encounter F#'],
      encounterId: encounter.patientSurveyId,
    };
  }));
  const matchingEncounter = existingEncounterNumbers.find((encounter) => encounter.encounterFNumber === encounterNumber);
  return matchingEncounter !== undefined ? {
    matchingPatientSurveyId: matchingEncounter.encounterId,
  } : null;
};
