import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import cx from 'classnames';
import { Beforeunload } from 'react-beforeunload';
import BulkUploadMercy from './bulkUploadMercy/BulkUpload';
import CombineAnalytics from './analyticsCombine/CombineAnalytics';
import CampaignManagement from './campaignManagement/CampaignManagement';
import ReloadPatients from './ReloadPatients';
import MessageBackfill from './messageBackfill/MessageBackfill';
import JSONEditor from './bulkUploads/JSONEditor';

import './App.scss';


type ViewsT = 'Bulk Upload Mercy' | 'Reload Encounters' | 'Reload Patients' | 'JSON Editor' | 'Message Backfill' | 'Manage Campaigns';
const App = () => {
  const [currentView, setCurrentView] = useState<ViewsT>('Bulk Upload Mercy');
  return (
    <div className="App">
      <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
      <div className="bg-light border-bottom py-2">
        {
          (['Bulk Upload Mercy', 'Reload Encounters', 'Reload Patients', 'JSON Editor', 'Message Backfill', 'Manage Campaigns'] as ViewsT[]).map((viewType) => (
            <Button
              onClick={async () => {
                if (viewType === currentView) {
                  return;
                }
                const didContinue = await swal(
                  'Change View',
                  `Any changes in ${currentView} will be lost`,
                  {
                    dangerMode: true,
                    buttons: ['No', 'Yes'],
                  }
                );
                if (didContinue) {
                  setCurrentView(viewType);
                }
              }}
              key={viewType}
              variant="link"
              className={cx('text-small text-decoration-none', {
                'font-weight-bold': currentView === viewType,
                'text-secondary': currentView !== viewType,
              })}
            >
              {viewType}
            </Button>
          ))
        }

      </div>
      <div className="overflow-auto">
        {currentView === 'Reload Encounters' && (
          <CombineAnalytics />
        )}
        {
          currentView === 'Reload Patients' && (
            <ReloadPatients />
          )
        }
        {
          currentView === 'Bulk Upload Mercy' && (
            <BulkUploadMercy />
          )
        }
        {
          currentView === 'JSON Editor' && (
            <JSONEditor />
          )
        }
        {
          currentView === 'Message Backfill' && (
            <MessageBackfill />
          )
        }
        {
          currentView === 'Manage Campaigns' && (
            <CampaignManagement />
          )
        }
      </div>
    </div>
  )
}

export default App;
