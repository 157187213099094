import React from 'react'
import Dropzone from 'react-dropzone'
import { Card } from 'react-bootstrap';
import { read, utils } from 'xlsx';
import csv from 'csvtojson';

type FileUploadT = {
  setFileName: (fileName: string) => void,
  fileName: null | string,
  placeholder: string,
  onUpload: (data: { [key: string]: string; }[], fileName: string) => boolean,
};


type Props =  ({
  allowExcel: true,
  // if any of the targetSheetNames are found, then
  // select that sheet
  targetSheetNames: Array<string>,
} | {
  allowExcel: false,
}) & FileUploadT;

const FileUpload = ({
  onUpload,
  fileName,
  placeholder,
  setFileName,
  ...excelOpts
}: Props) => (
  <>
    <Dropzone
      onDrop={(acceptedFiles) => {
        const fr = new FileReader();
        fr.onload = async () => {
          const fileName = acceptedFiles[0].name;
          const arrayBuffer = await acceptedFiles[0].arrayBuffer();

          const wb = read(arrayBuffer, {
            cellText:false,
            cellDates:true
          }); // parse the array buffer

          const frResult = await (async () => {
            if (fileName.endsWith('.csv') || !excelOpts.allowExcel) {
              return csv({
                ignoreEmpty: false,
                // @ts-expect-error
              }).fromString(fr.result)
            }
            const targetSheet = wb.SheetNames.find((sheetName) => (
              excelOpts.targetSheetNames.includes(sheetName)
            )) || wb.SheetNames[0];
            return utils.sheet_to_json(wb.Sheets[targetSheet], {
              rawNumbers: false,
              raw: false,
              defval: '',
              dateNF: 'mm/dd/yyyy'
            })
          })();
          // remove empty rows
          const frResultFiltered = frResult.filter((row) => Object.values(row).some((value) => value !== ''));

          if (onUpload(frResultFiltered, fileName)) {
            setFileName(fileName);
          }
        };
        fr.readAsText(acceptedFiles[0]);

      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Card {...getRootProps()} className="w-50 mx-auto mt-4 p-5 text-center">
          <input {...getInputProps()} />
          <div className="text-secondary font-weight-light">
            {placeholder}
          </div>
        </Card>
      )}
    </Dropzone>
    {fileName && (
      <div className="text-center mt-4 text-secondary">
        <span className="text-dark pr-2">File Name: </span>
        {fileName}
      </div>
    )}
  </>
);

export default FileUpload;
